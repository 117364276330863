import "./pricing.css";
import snow from "./components/snowflake.svg";
import check from "./components/checkbox-checked.svg";
import gem from "./components/gem.svg";
import React, { useState } from "react";

function Pricing() {
  const [isSoleAccount, setIsSoleAccount] = useState(true);

  const handleSoleAccountClick = () => {
    setIsSoleAccount(true);
  };

  const handleCoSponsoredClick = () => {
    setIsSoleAccount(false);
  };
  const PurchasePremium = () => {
    const phoneNumber = "+2348051551733"; // Replace with your WhatsApp number
    const message =
      "Hello, I'm __. I came across your website and I'm reaching out because I'm in need of a *PREMIUM* TradingView account."; // Customize your message
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = whatsappURL;
  };
  const PurchaseEssential = () => {
    const phoneNumber = "+2348051551733"; // Replace with your WhatsApp number
    const message =
      "Hello, I'm __. I came across your website and I'm reaching out because I'm in need of an *ESSENTIAL* TradingView account."; // Customize your message
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = whatsappURL;
  };
  const PurchaseEssentialCo = () => {
    const phoneNumber = "+2348051551733"; // Replace with your WhatsApp number
    const message =
      "Hello, I'm __. I came across your website and I'm reaching out because I'm in need of an *ESSENTIAL(PARTNER)* TradingView account."; // Customize your message
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = whatsappURL;
  };
  const PurchasePremiumCo = () => {
    const phoneNumber = "+2348051551733"; // Replace with your WhatsApp number
    const message =
      "Hello, I'm __. I came across your website and I'm reaching out because I'm in need of a *PREMIUM(PARTNER)* TradingView account."; // Customize your message
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.location.href = whatsappURL;
  };

  return (
    <>
      <div className="cnt-pr">
        <div className="pr-v-kb">
          <div className="pr-v">Our Pricing plans</div>

          <div className="pr-b-v">
            <button
              onClick={handleSoleAccountClick}
              className={isSoleAccount ? "active" : ""}
            >
              Sole Account
            </button>
            <button
              onClick={handleCoSponsoredClick}
              className={!isSoleAccount ? "active" : ""}
            >
              Co-Sponsored
            </button>
          </div>
        </div>
        {isSoleAccount ? (
          <div className="pr-pl">
            <div className="pr">
              <p className="pr-ac">Sole-Account</p>
              <p>Subscribe and enjoy full autonomy over your account</p>
            </div>

            <div className="pl-sc">
              <div className="pl">
                <div className="pl-crd">
                  <div className="pl-crd-m">
                    <img src={snow} alt="" />

                    <div>
                      <p>
                        15$<span>/per month</span>
                      </p>
                    </div>
                  </div>
                  <div className="pl-ty">
                    <p className="ty">Essential</p>
                    <p className="ty1">
                      Ideal and cost effective for individual practice
                    </p>
                  </div>
                  <div className="al-fe">
                    <button onClick={PurchaseEssential}>Order Now</button>
                    <p className="fe1l">Features:</p>
                    <div className="al-fe-0">
                      <div className="fe">
                        <img src={check} alt="" />
                        <p>No ads</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" />
                        <p>2 charts per tab </p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" /> <p>5 indicators per chart</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" /> <p>Bar replay</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" />{" "}
                        <p>10 parallel chart connections</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" /> <p>20 price alerts </p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" /> <p>Multiple watchlists </p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" /> <p>10k historical bars</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" />{" "}
                        <p>Volume profile & custom timeframes</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pl-crd">
                  <div className="pl-crd-m">
                    <img src={gem} alt="" />

                    <div>
                      <p>
                        60$<span>/per month</span>
                      </p>
                    </div>
                  </div>
                  <div className="pl-ty">
                    <p className="ty">Premium</p>
                    <p className="ty1">
                      Designed with more advanced tools for productivity
                    </p>
                  </div>
                  <div className="al-fe">
                    <button onClick={PurchasePremium}>Order Now</button>
                    <p className="fe1l">Features:</p>
                    <div className="al-fe-0">
                      <div className="fe">
                        <img src={check} alt="" /> <p>Chart data export</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" /> <p>8 charts per tab </p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" />{" "}
                        <p>25 indicators per chart</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" />{" "}
                        <p>Intraday Renko, Point & Figure charts</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" />{" "}
                        <p>50 parallel chart connections</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" /> <p>400 price alerts </p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" />{" "}
                        <p>Charts based on custom formulas </p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" /> <p>20k historical bars</p>
                      </div>
                      <div className="fe">
                        <img src={check} alt="" />{" "}
                        <p>Volume profile & custom timeframes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="cnt-pr">
            <div className="pr-pl pr-pl-inv">
              <div className="pl-sc">
                <div className="pl">
                  <div className="pl-crd">
                    <div className="pl-crd-m">
                      <img src={snow} alt="" />

                      <div>
                        <p>
                          7.5$<span>/per month</span>
                        </p>
                      </div>
                    </div>
                    <div className="pl-ty">
                      <p className="ty">Essential</p>
                      <p className="ty1">
                        Ideal and cost effective for individual practice
                      </p>
                    </div>
                    <div className="al-fe">
                      <button onClick={PurchaseEssentialCo}>Order Now</button>
                      <p>Features:</p>
                      <div className="al-fe-0">
                        <div className="fe">
                          <img src={check} alt="" /> <p>No ads</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" /> <p>2 charts per tab </p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" />{" "}
                          <p>5 indicators per chart</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" /> <p>Bar replay</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" />{" "}
                          <p>10 parallel chart connections</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" /> <p>20 price alerts </p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" /> <p>Multiple watchlists </p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" /> <p>10k historical bars</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" />{" "}
                          <p>Volume profile & custom timeframes</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-crd">
                    <div className="pl-crd-m">
                      <img src={gem} alt="" />

                      <div>
                        <p>
                          30$<span>/per month</span>
                        </p>
                      </div>
                    </div>
                    <div className="pl-ty">
                      <p className="ty">Premium</p>
                      <p className="ty1">
                        Designed with more advanced tools for productivity
                      </p>
                    </div>
                    <div className="al-fe">
                      <button onClick={PurchasePremiumCo}>Order Now</button>
                      <p className="fe1l">Features:</p>
                      <div className="al-fe-0">
                        <div className="fe">
                          <img src={check} alt="" /> <p>Chart data export</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" /> <p>8 charts per tab </p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" />{" "}
                          <p>25 indicators per chart</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" />{" "}
                          <p>Intraday Renko, Point & Figure charts</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" />{" "}
                          <p>50 parallel chart connections</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" /> <p>400 price alerts </p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" />{" "}
                          <p>Charts based on custom formulas </p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" /> <p>20k historical bars</p>
                        </div>
                        <div className="fe">
                          <img src={check} alt="" />{" "}
                          <p>Volume profile & custom timeframes</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pr">
                <p className="pr-ac">Co-Sponsored</p>
                <p>
                  Our signature offer which will require a partner to manage an
                  account
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default Pricing;
